@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.scroll-element-x::-webkit-scrollbar {
  height: 2px;
}

.scroll-element::-webkit-scrollbar {
  width: 2px;
}

.scroll-element::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.scroll-element::-webkit-scrollbar-thumb {
  border: 2px solid #674cb44d;
}

.scroll-element::-webkit-scrollbar-thumb:hover {
  background-color: #674cb4;
}
.scroll-none::-webkit-scrollbar {
  display: none;
}
.moment-insights {
  position: relative;
}
.moment-insights span {
  height: 18px;
  width: 18px;
  border: 0.75px solid #cfc7c7;
  position: absolute;
  border-radius: 50%;
  right: 10px;
  top: 10px;
}
.moment-insights.active span:before {
  content: "";
  height: 10px;
  width: 10px;
  /* background: #f7b544; */
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.moment-insights.active {
  /* background: #f7b54414;
  border: 1px solid rgba(247, 181, 68, 0.8) !important; */
}
.custom-radio [type="radio"]:checked,
.custom-radio [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.custom-radio [type="radio"]:checked + span,
.custom-radio [type="radio"]:not(:checked) + span {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.custom-radio [type="radio"]:checked + span:before,
.custom-radio [type="radio"]:not(:checked) + span:before {
  content: "";
  position: absolute;
  left: 0;
  top: -9px;
  width: 18px;
  height: 18px;
  border: 1px solid #cfd2d2;
  border-radius: 100%;
  background: #fff;
}
.custom-radio [type="radio"]:checked + span:after,
.custom-radio [type="radio"]:not(:checked) + span:after {
  content: "";
  width: 18px;
  height: 18px;
  background: #ffffff;
  position: absolute;
  top: -9px;
  left: 1px;
  border-radius: 100%;
  transition: all 0.2s ease;
  border: 6px solid #000;
}
.custom-radio [type="radio"]:not(:checked) + span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.custom-radio [type="radio"]:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  z-index: 9;
}
.content-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  z-index: 9;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-container .modebar {
  display: none !important;
}

.chart-container:hover .modebar {
  display: none !important;
}
select.ask-me {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

select.ask-me {
  background-image: url(../../lens_fe/src/assets/images/png/select-arrow.png);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.slider-img {
  filter: blur(0.8px);
}
.slider-img-active {
  border: 3px solid #8564e3;
  filter: blur(0px);
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 10px;
  font-weight: "normal";
  font-family: "Poppins";
  color: rgba(6, 6, 6, 0.7);
}

.custom-tooltip .tooltip-item {
  margin-bottom: 6px; /* Add gap between "Group Size" and "Number of Images" */
}

.custom-tooltip .tooltip-item span {
  color: rgba(6, 6, 6, 1);
  /* font-weight: bold; */
  margin-right: 2px; /* Adjust the padding between label and value for both x-axis and y-axis */
}
.filter-checkbox input:disabled + label {
  color: #000 !important;
}

.filter-checkbox input:disabled {
  border-color: #000;
}

.zero-value {
  color: #808080 !important;
}
